
import router from "@/router";
import {useStore} from "vuex";

export default {
	name: "About",
	components: {},
	setup() {
		const store = useStore()

		function onDone() {
			router.push("/home")
		}

		// store.dispatch("session/renew")

		return { onDone }
	}
}
